@import "../../scss/variables";

.layout {
  position: relative;
  background-image: linear-gradient($primary-color, $base-color-dark);
  color: $base-color-light;
  max-width: 100vw;
  // overflow-x: hidden;
  overflow: hidden;

  &-inner {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-shorter {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
  }
}

// Detects safari
@supports (background: -webkit-named-image(i)) {
  .layout-inner {
    min-height: -webkit-fill-available;
  }

  html {
    height: -webkit-fill-available;
  }
}

// Avoid Chrome to see Safari hack
// @supports (-webkit-touch-callout: none) {
//   .layout {
//     min-height: -webkit-fill-available;
//   }
// }
