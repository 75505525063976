@import "../../../scss/variables";

.practice-card {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 140px;
  padding: 0.875rem;
  margin: 1rem;
  background: $navy-bg-color !important;
  border-radius: 10px;
  border: 1px solid $gray-bg-color;

  &-title {
    font-size: 1.3125rem;
    text-align: left;
    font-weight: 600;
  }

  &-minutes {
    color: $secondary-color;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  &-core {
    background: $primary-color;
  }
}

@media (max-width: $max-width-mobile) {
  .practice-card {
    width: 140px;
    height: 120px;
    margin: 0.8rem;
    padding: 0.75rem;
  }
}

@media (max-width: $max-width-mobile-sm) {
  .practice-card {
    width: 160px;
    height: 110px;
  }
}
