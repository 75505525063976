.home-page {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  -webkit-transform: translate(-50%, -40%);
  width: 100vw;
  padding: 0.5rem;

  &-title {
    margin: 1rem 0;
  }

  &-language-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
