/* Current file, _util.scss is imported into App component */
@import "./variables";
// Import Nunito Sans Font -  font weights: 400/600/700
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primary-color;
  color: $base-color-light;
}

button {
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  user-select: none;
  text-decoration: none;
  font-family: $font-family;
  font-weight: 600;
  outline: none;
}

.green-color {
  color: $secondary-color;
}

.center {
  text-align: center;
}

.font-bold {
  font-weight: 700;
}

.font-semi-bold {
  font-weight: 600;
}

// Paddings & Margins
.my-1 {
  margin: 1rem 0;
}
.p-1 {
  padding: 1rem;
}

.hidden {
  opacity: 0;
}

// Styling for font awesome icons
.icon-fa {
  margin: 0 0.2rem;
}

@media (max-width: $max-width-mobile) {
  // Decrease font size in small devices
  html {
    font-size: 80%;
  }
}
