@import "../../../scss/variables";

.btn-duration {
  padding: 0.5rem;
  // Remove tap highlight color on ios
  -webkit-tap-highlight-color: transparent;

  &:active {
    color: $secondary-color;
    .duration-text {
      font-weight: 700;
    }
  }
}
.duration-text {
  font-size: 2rem;
  font-weight: 400;
}

@media (max-height: 600px) {
  .duration-text {
    font-size: 1.5rem;
  }
}
@media (max-height: 500px) {
  .duration-text {
    font-size: 1.125rem;
  }
}

// Apply hover for desktop
@media (hover: hover) and (pointer: fine) {
  .btn-duration:hover {
    color: $secondary-color;
    .duration-text {
      font-weight: 700;
    }
  }
}
