@import "../../../scss/variables";

.icon-logo {
  width: 169px;
  height: 56px;
}

@media (max-width: $max-width-mobile) {
  .icon-logo {
    width: 135px;
    height: 45px;
  }
}
