@import "../../scss/variables";

$mode-icon-size: 74px;
$mode-icon-size-md: 55px;
$mode-icon-size-sm: 40px;

$play-pause-icon-size: 50px;
$play-pause-icon-size-sm: 43px;

$forward-rewind-icon-size: 70px;
$forward-rewind-icon-size-sm: 52px;

// icons for play & pause
.icon-play-pause {
  width: $play-pause-icon-size;
  height: $play-pause-icon-size;
}

// icons for sitting & active
.icon-mode {
  width: $mode-icon-size;
  height: auto;
  margin: 0.2rem;
}

// icons for forward & rewind
.icon-forward-rewind {
  width: $forward-rewind-icon-size;
  height: $forward-rewind-icon-size;
}

@media (max-width: $max-width-tablet) {
  .icon-mode {
    width: $mode-icon-size-md;
  }
}

@media (max-width: $max-width-mobile) {
  .icon-mode {
    width: $mode-icon-size-sm;
  }
  .icon-play-pause {
    width: $play-pause-icon-size-sm;
    height: $play-pause-icon-size-sm;
  }
  .icon-forward-rewind {
    width: $forward-rewind-icon-size-sm;
    height: $forward-rewind-icon-size-sm;
  }
}
