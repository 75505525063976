@import "../../../scss/variables";

.section-explore-more {
  padding-top: 2rem;
  background: $base-color-light;

  &-inner {
    max-width: $website-width;
    margin: auto;
  }

  &-title {
    color: $primary-color;
    font-weight: 700;
    font-size: 2.3rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 4.125rem;
    text-align: center;
    max-width: 600px;
  }

  .tools-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding: 0 2rem;
    padding-bottom: 4rem;
    // IE
    grid-template-rows: 1fr;
  }

  .tool {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 1.5rem;

    &-title {
      font-size: 1.5rem;
      color: $primary-color;
      width: 100%;
    }

    &-description {
      font-size: 1rem;
      color: $base-color-dark;
      font-weight: 400;
      margin: 1rem 0;
      width: 100%;
    }

    .btn-custom {
      margin-top: auto;
    }
  }
}

@media (max-width: $max-width-tablet) {
  // Display each tool in each row
  .section-explore-more {
    .tools-container {
      display: grid;
      /* autoprefixer: ignore next */
      grid-template-columns: 1fr;
      padding: 0;

      // Add dotted lines when shown vertically
      .tool {
        border-bottom: 1px dashed #363b7f50;
        padding: 0 2rem;
        padding-bottom: 1.5rem;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media (max-width: $max-width-mobile) {
  .section-explore-more {
    &-title {
      font-size: 1.8rem;
    }
  }
}
