@import "../../../scss/variables";

.play-pause {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .toggle {
    cursor: pointer;
    background: none;

    &:focus {
      outline: 0;
    }
  }
}
