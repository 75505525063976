@import "../../../scss/variables";

$btn-control-size: 65px;
$btn-control-size-sm: 55px;

$icon-control-size: 55px;
$icon-control-size-sm: 45px;

$progress-dot-diameter: 8px;
$progress-dot-radius: 4px;

$audio-player-diameter: 263px;

.main-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;

  &-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1.25rem auto;
  }

  .duration-elapsed {
    margin: 0 1.25rem;
  }

  .btn-download {
    margin: 0.5rem auto;
  }
}
