@import "../../../scss/variables";

.icon-stars {
  position: absolute;
  width: 100vw;
  height: 100%;
  max-height: 300px;
  background: url("../../../assets/icons/stars.svg") center/auto 100%;
}

@media (max-width: $max-width-mobile) {
  .icon-stars {
    max-height: 150px;
  }
}
