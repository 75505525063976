@import "../../scss/variables";

.btn-custom {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  color: $base-color-light;
  background: $primary-color;
  border: none;
  margin: 0.35rem;
  -webkit-appearance: none;
  appearance: none;
  user-select: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
  font-family: $font-family;
  border-radius: 30px;
  transition: background 300ms ease-in;
  outline: none;

  &:hover {
    background: $primary-color-light;
  }

  &:disabled {
    cursor: not-allowed;
    background: $disabled-color-light;
    color: $disabled-color-dark;
    border: $disabled-color-light;
    box-shadow: none;

    &:hover,
    &:active {
      background: $disabled-color-light;
      color: $disabled-color-dark;
      border: $disabled-color-light;
    }
  }
}

.btn-no-styling {
  background: none !important;
}

// Secondary button colors
.btn-custom-secondary {
  color: $primary-color;
  background: $secondary-color;

  &:hover {
    background: $secondary-color-dark;
  }
}
