@import "../../scss/variables";

.modal-custom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;

  border: 1px solid rgb(204, 204, 204);
  background: $base-color-light;
  overflow: auto;
  border-radius: 4px;
  outline: none;

  max-width: 700px;
  text-align: center;
  padding: 1rem 0;
  color: $base-color-dark;

  &-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 1.5rem 2.5rem;
  }

  &-title {
    text-align: center;
    margin: 1rem 0;
    margin-top: 1.5rem;
    width: 100%;
  }

  &-description1 {
    font-size: 1.2rem;
    margin: 1rem 0;
    width: 100%;
  }

  &-description2 {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .btn-learn-more {
    margin-top: 2rem;
  }
}

.modal-custom-overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

@media (max-width: $max-width-mobile) {
  .modal-custom {
    &-title {
      font-size: 1.825rem;
    }
    &-inner {
      padding: 1.25rem;
    }
  }
}
