@import "../../../scss/variables";

.audio-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-inner {
    margin-top: 100px;
  }

  &-info {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    &-title {
      text-align: center;
      font-size: 2.5rem;
      padding: 1rem 1.25rem;
    }

    &-description {
      font-size: 1.25rem;
      max-width: 580px;
      text-align: center;
      padding: 1rem;
    }
  }
}

// Hide audio screen info when device height is too short
// @media (max-height: 500px) {
//   .audio-screen-info {
//     display: none;
//   }
// }

@media (max-width: $max-width-mobile) {
  .audio-screen {
    &-inner {
      margin-top: 50px;
    }

    &-info {
      &-title {
        font-size: 1.75rem;
      }
      &-description {
        font-size: 1rem;
      }
    }
  }
}
