// Orbiting animation for ovals on the left (clockwise)
@keyframes orbitLeft {
  0% {
    transform: translate(-20px, 0px);
  }
  13% {
    transform: translate(-14px, 4px);
  }
  25% {
    transform: translate(-10px, 10px);
  }
  37% {
    transform: translate(-14px, 16px);
  }
  50% {
    transform: translate(-20px, 20px);
  }
  62% {
    transform: translate(-26px, 16px);
  }
  75% {
    transform: translate(-30px, 10px);
  }
  87% {
    transform: translate(-26px, 4px);
  }
  100% {
    transform: translate(-20px, 0px);
  }
}

// Orbiting animation for ovals on the left (counter-clockwise)
@keyframes orbitRight {
  0% {
    transform: translate(20px, 0px);
  }
  13% {
    transform: translate(14px, 4px);
  }
  25% {
    transform: translate(10px, 10px);
  }
  37% {
    transform: translate(14px, 16px);
  }
  50% {
    transform: translate(20px, 20px);
  }
  62% {
    transform: translate(26px, 16px);
  }
  75% {
    transform: translate(30px, 10px);
  }
  87% {
    transform: translate(26px, 4px);
  }
  100% {
    transform: translate(20px, 0px);
  }
}

// Orbiting animation for ovals on the left (Moving less)
@keyframes orbitLeftLess {
  0% {
    transform: translate(-20px, 0px);
  }
  13% {
    transform: translate(-17px, 2px);
  }
  25% {
    transform: translate(-15px, 5px);
  }
  37% {
    transform: translate(-17px, 8px);
  }
  50% {
    transform: translate(-20px, 10px);
  }
  62% {
    transform: translate(-23px, 8px);
  }
  75% {
    transform: translate(-25px, 5px);
  }
  87% {
    transform: translate(-23px, 2px);
  }
  100% {
    transform: translate(-20px, 0px);
  }
}

// Orbiting animation for ovals on the left (Moving less)
@keyframes orbitRightLess {
  0% {
    transform: translate(20px, 0px);
  }
  13% {
    transform: translate(17px, 2px);
  }
  25% {
    transform: translate(15px, 5px);
  }
  37% {
    transform: translate(17px, 8px);
  }
  50% {
    transform: translate(20px, 10px);
  }
  62% {
    transform: translate(23px, 8px);
  }
  75% {
    transform: translate(25px, 5px);
  }
  87% {
    transform: translate(23px, 2px);
  }
  100% {
    transform: translate(20px, 0px);
  }
}
