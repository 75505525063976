@import "../../../scss/variables";

.btn-close {
  z-index: 2;
  position: absolute;
  top: 2.75rem;
  right: 5rem;
  padding: 0;
  margin: 0;
  border-radius: 0;

  &-modal {
    top: 15px;
    right: 15px;
  }
}

.icon-close {
  width: 43px;
  height: 43px;
  // Use smaller size for modal close icon
  &-modal {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: $max-width-tablet) {
  .btn-close {
    top: 1.5rem;
    right: 1.5rem;

    &-modal {
      top: 15px;
      right: 15px;
    }
  }
  .icon-close {
    width: 35px;
    height: 35px;

    &-modal {
      width: 25px;
      height: 25px;
    }
  }
}

@media (max-width: $max-width-mobile) {
  .icon-close {
    width: 25px;
    height: 25px;

    &-modal {
      width: 23px;
      height: 23px;
    }
  }
}
