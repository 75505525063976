@import "../../../scss/variables";

.btn-back {
  z-index: 2;
  position: absolute;
  top: 2.75rem;
  left: 5rem;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.icon-back {
  width: 25px;
  height: auto;
}

@media (max-width: $max-width-tablet) {
  .btn-back {
    top: 1.5rem;
    left: 1.5rem;
  }
  .icon-back {
    width: 21px;
  }
}

@media (max-width: $max-width-mobile) {
  .icon-back {
    width: 15px;
  }
}
