@import "../../../scss/variables";

.circle-progress-bar {
  position: relative;

  &-background,
  &-inner,
  &-crawler {
    fill: none;
  }

  &-crawler {
    stroke: $secondary-color;
  }

  &-background {
    stroke: $navy-gray-color-dark;

    &-initial {
      stroke: $secondary-color;
    }
  }

  &-inner {
    position: absolute;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &-dot {
    fill: $moderate-blue-color;
  }
}
