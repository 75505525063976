@import "../../../../scss/variables";

// Oval bottom left
.icon-oval-3 {
  left: 50px;
  bottom: -100px;
}

// Oval bottom left
.icon-oval-4 {
  right: 0;
  bottom: -120px;
}

@media (max-width: 1349px) {
  .icon-oval-3 {
    left: 0;
    width: 290px;
  }
  .icon-oval-4 {
    width: 290px;
  }
}

@media (max-width: 1169px) {
  .icon-oval-3 {
    width: 250px;
  }
  .icon-oval-4 {
    width: 250px;
  }
}

@media (max-width: 1050px) {
  .icon-oval-3 {
    opacity: 0.25;
  }
  .icon-oval-4 {
    opacity: 0.7;
    width: 250px;
  }
}

@media (max-width: 970px) {
  .icon-oval-3 {
    width: 250px;
  }
  .icon-oval-4 {
    width: 250px;
  }
}

@media (max-width: $max-width-tablet) {
  .icon-oval-3 {
    width: 220px;
  }
  .icon-oval-4 {
    width: 200px;
    bottom: -150px;
  }
}

@media (max-width: $max-width-mobile) {
  .icon-oval-3 {
    width: 160px;
    bottom: -110px;
  }
  .icon-oval-4 {
    width: 150px;
    bottom: -200px;
  }
}
