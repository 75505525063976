@import "../../../scss/variables";

$option-width: 170px;
$option-height: 170px;
$option-width-sm: 150px;
$option-height-sm: 150px;

$option-width-mobile: 110px;
$option-height-mobile: 110px;
$option-width-mobile-sm: 90px;
$option-height-mobile-sm: 90px;

.option-screen {
  overflow: hidden;

  .option-wrap {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    align-items: center;
  }
}

.option-card-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  margin: auto;

  > * {
    margin: 1rem;
  }

  &-duration {
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
    > * {
      margin: 15px;
    }
  }

  &-speaker {
    margin-top: 3rem;
  }
}

@media (max-height: 600px) {
  .option-card-wrap {
    &-duration {
      margin-top: 1rem;
    }
  }
}

@media (max-width: $max-width-tablet) {
  .option-screen {
    .option-wrap {
      margin-top: 75px;
    }
  }
}

@media (max-width: $max-width-mobile) {
  .option-screen {
    .option-wrap {
      margin-top: 50px;
    }
  }

  .option-card-wrap {
    > * {
      margin: 0.6rem;
    }
  }
}
