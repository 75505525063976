@import "../../../scss/variables";

$speaker-icon-size: 112px;
$speaker-icon-size-sm: 71px;

.icon-speaker {
  margin: 0.5rem;
  width: $speaker-icon-size;
  height: $speaker-icon-size;
  border-radius: 50%;
  border: 3px solid $base-color-light;
}

@media (max-width: $max-width-mobile) {
  .icon-speaker {
    max-width: $speaker-icon-size-sm;
    max-height: $speaker-icon-size-sm;
    border-width: 2px;
  }
}
