// Purple colors
$primary-color: #363b7f;
$primary-color-light: #6565af;
$primary-color-dark: #001652;

// Green colors
$secondary-color: #56fdd2;
$secondary-color-2: #4dffc7;

$secondary-color-light: #92ffff;
$secondary-color-dark: #00c9a1;

$base-color-light: #fff;
$base-color-dark: #09192d;

// Blue colors
$sky-blue-color: #1092c8;
$accent-blue-color: #4ac9e2;

// Disabled colors
$disabled-color-light: #ccc;
$disabled-color-dark: #888;

// Other colors
$navy-gray-color: #28334e;
$navy-gray-color-dark: rgba(9, 25, 45, 0.325);
$navy-bg-color: rgba(9, 25, 45, 0.302);
$gray-bg-color: rgba(255, 255, 255, 0.251);
$moderate-blue-color: #397998;

$font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

// Device sizes
$max-width-tablet: 768px;
$max-width-mobile: 500px;
$max-width-mobile-sm: 319px;

// Website width
$website-width: 1300px;

// Max width for activities container
$max-width-activities: 700px;
// Max width for header
$max-width-header: 1300px;
