@import "../../scss/variables";

$header-height: 120px;
$header-height-sm: 100px;

.main-header {
  height: $header-height;
  margin-bottom: 3rem;
  padding: 0.5rem 1.2rem;
  &-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    /* autoprefixer: ignore next */
    justify-items: center;
    /* autoprefixer: ignore next */
    align-items: center;
    height: $header-height;
    margin: 0 auto;
    padding: 0.6rem;
    max-width: $max-width-header;

    // IE
    grid-template-rows: 1fr;
    & > * {
      align-self: center;
      justify-self: center;
    }

    .btn-about {
      color: $secondary-color;
      font-size: 1.125rem;
      margin-left: auto;
      margin-right: 3rem;
      border-radius: 0px;
    }
  }
}

@media (max-width: $max-width-tablet) {
  .main-header {
    margin-bottom: 2rem;

    &-inner {
      .btn-about {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $max-width-mobile) {
  .main-header {
    padding: 0.25rem 0.425rem;
    height: $header-height-sm;

    &-inner {
      padding: 0rem;
      height: $header-height-sm;
    }
  }
}
