@import "../../../scss/variables";
@import "../../../scss/animations";

// Ovals on the left
.icon-oval-1,
.icon-oval-3 {
  position: absolute;
  animation: orbitLeft 10s linear infinite; /* Chrome, Firefox 16+, IE 10+, Safari 5 */
  -webkit-animation: orbitLeft 10s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: orbitLeft 10s linear infinite; /* Firefox 5-15 */
  -o-animation: orbitLeft 10s linear infinite; /* Opera 12+ */
}

// Ovals on the right
.icon-oval-2,
.icon-oval-4 {
  position: absolute;
  animation: orbitRight 10s linear infinite; /* Chrome, Firefox 16+, IE 10+, Safari 5 */
  -webkit-animation: orbitRight 10s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: orbitRight 10s linear infinite; /* Firefox 5-15 */
  -o-animation: orbitRight 10s linear infinite; /* Opera 12+ */
}

// Oval top left
.icon-oval-1 {
  z-index: -1;
  left: 0;
  bottom: -150px;
  width: 400px;
  height: 100%;
}

// Oval top right
.icon-oval-2 {
  z-index: -1;
  top: 200px;
  width: 150px;
  right: 0;
}

@media (max-width: 1349px) {
  .icon-oval-1 {
    width: 300px;
  }
  .icon-oval-2 {
    width: 140px;
  }
}

@media (max-width: 950px) {
  .icon-oval-2 {
    opacity: 0.3;
  }
}

@media (max-width: $max-width-tablet) {
  .icon-oval-1 {
    width: 200px;
  }
  .icon-oval-2 {
    width: 130px;
  }
}

@media (max-width: $max-width-mobile) {
  .icon-oval-1 {
    width: 150px;
  }
  .icon-oval-2 {
    width: 90px;
  }

  // Ovals on the left
  .icon-oval-1,
  .icon-oval-3 {
    animation: orbitLeftLess 10s linear infinite; /* Chrome, Firefox 16+, IE 10+, Safari 5 */
    -webkit-animation: orbitLeftLess 10s linear infinite; /* Chrome, Safari 5 */
    -moz-animation: orbitLeftLess 10s linear infinite; /* Firefox 5-15 */
    -o-animation: orbitLeftLess 10s linear infinite; /* Opera 12+ */
  }

  // Ovals on the right
  .icon-oval-2,
  .icon-oval-4 {
    animation: orbitRightLess 10s linear infinite; /* Chrome, Firefox 16+, IE 10+, Safari 5 */
    -webkit-animation: orbitRightLess 10s linear infinite; /* Chrome, Safari 5 */
    -moz-animation: orbitRightLess 10s linear infinite; /* Firefox 5-15 */
    -o-animation: orbitRightLess 10s linear infinite; /* Opera 12+ */
  }
}
