@import "../../scss/variables";

.page-template {
  &-title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width-activities;
    margin: auto;
    padding: 1rem;

    h1.page-template-title {
      font-size: 2.5rem;
      max-width: 400px;
    }

    .btn-sort {
      align-self: flex-end;
    }
  }

  .practice-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* autoprefixer: ignore next */
    justify-content: center;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: $max-width-activities;
    // IE
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .disclaimer {
    max-width: 640px;
    margin: auto;
    font-size: 0.715rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 0 1.25rem;
  }
}

.icon-oval-wrap {
  position: relative;
  width: 100%;
}

.section-start-meditation {
  padding: 4rem;

  &-title {
    font-size: 3.5rem;
    color: $secondary-color;
    margin-right: 5rem;
  }

  &-sub-title {
    font-weight: 700;
    color: $base-color-light;
    margin-top: 2rem;
  }
}

@media (max-width: $max-width-tablet) {
  .page-template {
    &-title-wrap {
      flex-direction: column;
      max-width: 480px;

      h1.page-template-title {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .btn-sort {
        align-self: flex-start;
        margin-left: 1.2rem;
      }
    }

    .practice-cards {
      /* autoprefixer: ignore next */
      grid-template-columns: 1fr 1fr;
      max-width: 480px;
    }
  }

  .section-start-meditation {
    padding: 3rem;
    &-title {
      font-size: 2rem;
      margin-right: 1.5rem;
    }
  }
}

@media (max-width: $max-width-mobile) {
  .page-template {
    &-title-wrap {
      flex-direction: column;
      margin: auto;
      padding: 0 1rem;
      max-width: 340px;

      h1.page-template-title {
        font-size: 2rem;
      }

      .btn-sort {
        align-self: flex-start;
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
    .practice-cards {
      /* autoprefixer: ignore next */
      grid-template-columns: 1fr 1fr;
      max-width: 340px;
    }
  }
  .section-start-meditation {
    padding: 2.5rem;
    &-title {
      margin-right: 1rem;
    }
  }
}

@media (max-width: $max-width-mobile-sm) {
  .page-template {
    &-title-wrap {
      max-width: 280px;
    }
    .practice-cards {
      /* autoprefixer: ignore next */
      grid-template-columns: 1fr;
      max-width: 200px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
