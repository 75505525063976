@import "../../../scss/variables";

.text-speaker-name {
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 1rem;
}

.btn-speaker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.125rem;
  width: 260px;
  height: 200px;

  &:active {
    .icon-speaker {
      border-color: $secondary-color;
    }
    .text-speaker-name {
      color: $secondary-color;
      font-weight: 700;
    }
  }
}

@media (max-width: $max-width-mobile) {
  .text-speaker-name {
    font-size: 1rem;
    margin-top: 0.75rem;
  }

  .btn-speaker {
    margin: 0 0.3rem;
    width: 130px;
    height: 150px;
  }
}

// Apply hover for desktop
@media (hover: hover) and (pointer: fine) {
  .btn-speaker {
    &:hover {
      .icon-speaker {
        border-color: $secondary-color;
      }
      .text-speaker-name {
        color: $secondary-color;
        font-weight: 700;
      }
    }
  }
}
