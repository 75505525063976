@import "../../scss/variables";

.links-to-pages {
  padding: 1rem;
  background: $base-color-dark;
  color: #fff;

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.5rem 0;
  }
}
