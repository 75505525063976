@import "../../../scss/variables";

$arrow-size: 13px;

.mode-with-description {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mode-description {
    opacity: 0.875;
    position: relative;
    margin-top: 0.5rem;
    max-width: 290px;
    padding: 0 0.75rem;
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 1.125rem;
  }

  .icon-mode-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $navy-bg-color;
    border: 1px solid $gray-bg-color;
    padding: 3rem 1rem 1rem 1rem;
    border-radius: 50%;
    width: 222px;
    height: 215px;

    > span {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 1.325rem;
    }
  }
}

@media (max-height: 725px) {
  .mode-description {
    font-size: 0.9rem !important;
  }
}

// Hide mode description on short devices
// @media (max-height: 500px) {
//   .mode-description {
//     display: none;
//   }
// }

@media (max-width: $max-width-tablet) {
  .mode-with-description {
    .mode-description {
      margin-top: 0;
      font-size: 1rem;
    }

    .icon-mode-wrap {
      padding: 2.2rem 1rem 1rem 1rem;
      width: 168px;
      height: 161px;

      > span {
        margin-top: 0.7rem;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: $max-width-mobile) {
  .mode-with-description {
    .mode-description {
      max-width: 220px;
    }

    .icon-mode-wrap {
      padding: 2.2rem 1rem 1rem 1rem;
      width: 133px;
      height: 129px;
    }
  }
}
