@import "../../scss/variables";

.mytt {
  min-width: 70px;
  background: $navy-bg-color !important;

  &.place-bottom {
    &::after {
      border-bottom-color: $navy-bg-color !important;
    }
  }
}
